/* global google */
import Splide from "@splidejs/splide";
import {FloatLabels} from "@keodesign/float-labels";
import Plyr from "plyr";
import {Loader} from "@googlemaps/js-api-loader";
import GLightbox from "glightbox";

export function init() {
	const map_click_list = document.querySelectorAll(".map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
	setupSlider();
	setupTestimonialSlider();
	setup_view_option();
	filter_research_on_submit();
	new FloatLabels(".js-float-wrap");
	new Plyr(".youtube-player",{
		hideControls:true,
		controls:["play-large"],
		ratio: "21:9"
	});
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
	setup_map();
	
}


export function setupSlider(){
	var officeSlider = document.querySelectorAll(".office-slider");
	
	if(officeSlider){
		for (var i = 0; i < officeSlider.length; i++ ) {
			var slider = officeSlider[i].querySelector(".slider");
			var officeSplide = null;

			
			officeSplide = new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: true,
				arrows    : false,
				autoplay: true,
			}).mount();
				
		
			var splideNav = officeSlider[i].querySelectorAll(".splide-nav");
			for (var j = 0; j < splideNav.length; j++ ) {
				splideNav[j].addEventListener("click", (e) => {
					var index = e.target.dataset.index;
					officeSplide.go(parseInt(index));
				});
				
			}
		}
	}
	
}

export function setupTestimonialSlider(){
	var testimonialSlider = document.querySelectorAll(".testimonial-slider");
	
	if(testimonialSlider){
		for (var i = 0; i < testimonialSlider.length; i++ ) {
			var slider = testimonialSlider[i].querySelector(".slider");
			
			new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: true,
				arrows    : false,
				autoplay: true,
			}).mount();
		}
	}
}

export function filter_research_on_submit() {
	const filter_casestudy = document.querySelector("#filter_research");
	if (filter_casestudy){
		filter_casestudy.addEventListener("submit", (event) => {
			event.preventDefault();
			
			const formFields = filter_casestudy.elements;

			// Iterate through the form fields
			for (let i = formFields.length -1; i >= 0; i--) {
				let field = formFields[i];
	
				// Check if the field is not a button and is blank
				if (field.type !== "submit" && field.value.trim() === "") {
					// Remove the blank field
					field.name = "";
				}
			}
			filter_casestudy.submit();
		});
	}
}

export function setup_view_option(){
	const view_option = document.querySelector(".view-option");
	if (view_option){
		let grid_btn = view_option.querySelector("#grid-btn");
		let list_btn = view_option.querySelector("#list-btn");
		const content_wrapper_grid = document.querySelector(".content-wrapper-grid");
		const content_wrapper_list = document.querySelector(".content-wrapper-list");
		
		grid_btn.addEventListener("click", () =>{
			grid_btn.classList.add("active");
			list_btn.classList.remove("active");
			content_wrapper_grid.classList.remove("d-none");
			content_wrapper_list.classList.add("d-none");
		});
	
		list_btn.addEventListener("click", () =>{
			list_btn.classList.add("active");
			grid_btn.classList.remove("active");
			content_wrapper_list.classList.remove("d-none");
			content_wrapper_grid.classList.add("d-none");
		});
	}
}

export function setup_map(){
	const map_element = document.querySelector("#map");
	const map_key_el = document.querySelector("#map-key");


	if(map_element && map_key_el){
		const map_key = map_key_el.value;
		const loader = new Loader({
			apiKey: map_key,
			version: "weekly"
		});

		loader.importLibrary("maps").then(({Map}) => {
			initMap(map_element, Map);
		}).catch((e) => {
			console.log(e);
		});
		
	}
}

export function initMap(map_element, Map){
	let office_list = document.querySelectorAll(".office-click");
	if (office_list){
		let bounds = new google.maps.LatLngBounds();   

		let map = new Map(map_element, {
			zoom: 16,
		});
		let marker_pos = null;
		

		office_list.forEach(office => {
			const latitude = office.dataset.lat;
			const longitude = office.dataset.long;
			const id = office.dataset.id;
			let marker_dict = {};

			if (id && latitude && longitude){
				marker_pos = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
				bounds.extend(marker_pos);

				const marker = new google.maps.Marker({
					position: marker_pos,
					map: map,
					office_id:id
				});

				marker.addListener("click", () => {
					
					map.panTo(marker.getPosition());
					setTimeout(() => {
						// Your code here
						map.setZoom(17);
					}, 100);

					const map_wrapper = document.querySelector("#office-map-wrapper");


					let infowindow_list = document.querySelectorAll(".office-infowindow");
					if (infowindow_list){
						infowindow_list.forEach(infowindow => {
							infowindow.style.display = "none";
						});
						let selected_infowindow = document.querySelectorAll(".office-infowindow-" + marker.office_id);
						if (selected_infowindow){
							selected_infowindow.forEach(sel_infowindow => {
								sel_infowindow.style.display = "block";
							});
						}
						
					}
					if(map_wrapper){
						if(!map_wrapper.classList.contains("col-sm-6")){
							map_wrapper.classList.add("col-sm-6");
						}
					}

					
				});
				
				marker_dict[id] = marker;

				office.addEventListener("click", (e) => {
					e.preventDefault;
					let office_id = office.dataset.id;
					new google.maps.event.trigger( marker_dict[office_id], "click" );
				});
			}
		});

		if (office_list.length == 1){
			office_list[0].click();
		}
		map.fitBounds(bounds);
		document.getElementById("map").parentNode.querySelector(".kms-loader").remove();
	}
	
}
window.addEventListener("DOMContentLoaded", init);